import {
    registerAppointmentPayment,
    registerAppointmentPayment1,
    registerAppointmentPayment2
} from '@api/paymentServiceAPI';
import {PaymentType} from '@enums';
import {CheckCircled, TriangleWarning} from '@icons';
import {selectSlot, setPatientInConsultationSlot} from '@sliceConsultation';
import {OrderTypes} from '@utils/constants';
import {currencyFormatter} from '@utils/utils';
import {Button, Radio, RadioChangeEvent, Space} from 'antd';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {PaymentDirectlyModal} from 'src/modals/PaymentDirectlyModal/PaymentDirectlyModal';
import {IStepProps} from '../../ConsultationSignUpPage';
import styles from './PaymentStep.module.scss';
import {selectPaymentServiceActive} from "../../../../../store/organization";

export const PaymentStep = ({ data }: IStepProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const slot = useSelector(selectSlot);
    const [showPaymentDirectlyModal, setShowPaymentDirectlyModal] = useState(false);

    const [paymentType, setPaymentType] = useState<PaymentType>(PaymentType.MAIN_COUNTRY);

    const paymentServiceActive = useSelector(selectPaymentServiceActive);

    useEffect(() => {
        if(!paymentServiceActive) {
            setPaymentType(PaymentType.MAIN_COUNTRY);
            pay();
        }
    }, [slot]);

    const pay = () => {
        if (slot?.appointmentId) {
            if (paymentType === PaymentType.MAIN_COUNTRY) {
                registerAppointmentPayment(
                    {
                        appointmentId: slot.appointmentId,
                        failUrl: `${process.env.REACT_APP_PAYMENT_URL}-fail?order=${OrderTypes.consultation}&entityId=${slot.appointmentId}`,
                        returnUrl: `${process.env.REACT_APP_PAYMENT_URL}-ok?order=${OrderTypes.consultation}&entityId=${slot.appointmentId}`,
                    },
                    {},
                ).then((res) => {
                    if (res.data.formUrl) {
                        window.location.href = res.data.formUrl;
                    } else {
                        navigate('/');
                    }
                });
            } else if (paymentType === PaymentType.PRODAMUS) {
                registerAppointmentPayment1(
                    {
                        appointmentId: slot.appointmentId,
                        failUrl: `${process.env.REACT_APP_PAYMENT_URL}-prodamus-fail?order=${OrderTypes.consultation}&entityId=${slot.appointmentId}`,
                        returnUrl: `${process.env.REACT_APP_PAYMENT_URL}-prodamus-ok?order=${OrderTypes.consultation}&entityId=${slot.appointmentId}`,
                        params: { customer_extra: t('payment.prodamus_redirect_info') },
                    },
                    {},
                ).then((res) => {
                    if (res.data.formUrl) {
                        window.location.href = res.data.formUrl;
                    } else {
                        navigate('/');
                    }
                });
            }
            else if (paymentType == PaymentType.MTS) {
                registerAppointmentPayment2(
                    {
                        appointmentId: slot.appointmentId,
                        failUrl: `${process.env.REACT_APP_PAYMENT_URL}-mts-fail?order=${OrderTypes.consultation}&entityId=${slot.appointmentId}`,
                        returnUrl: `${process.env.REACT_APP_PAYMENT_URL}-mts-ok?order=${OrderTypes.consultation}&entityId=${slot.appointmentId}`
                    },
                    {},
                ).then((res) => {
                    if (res.data.formUrl) {
                        window.location.href = res.data.formUrl;
                    } else {
                        navigate('/');
                    }
                });
            }
        }
    };

    const onChangePaymentType = (e: RadioChangeEvent) => {
        setPaymentType(e.target.value);
    };

    return paymentServiceActive ? (
        <div className={styles.wrapper}>
            <h5 className={styles.title}>{t('consultation.PaymentStep.title')}</h5>

            <Space size={11} align="baseline" className={styles.subtitle}>
                <CheckCircled />
                {t('consultation.PaymentStep.subtitle')}
            </Space>
            <div
                className={styles.description}
                dangerouslySetInnerHTML={{
                    __html: t('consultation.PaymentStep.description'),
                }}
            />

            <div className={styles['directly-payment-block']}>
                <div className={styles['warning-icon']}>
                    <TriangleWarning />
                </div>
                <p>
                    {t('consultation_sign_up.payment_step.contact_manager_if_paid')}{' '}
                    <span onClick={() => setShowPaymentDirectlyModal(true)}>{t('consultation_sign_up.payment_step.in_instruction')}</span>
                </p>
            </div>

            <div className="mb-4">
                <Radio.Group value={paymentType} onChange={onChangePaymentType}>
                    <Space direction="vertical">
                        <Radio value={PaymentType.MAIN_COUNTRY} className="mb-3">
                            {t(`consultation_sign_up.payment_step.${PaymentType.MAIN_COUNTRY}`)}
                        </Radio>
                        <Radio value={PaymentType.PRODAMUS} className="mb-3">
                            {t(`consultation_sign_up.payment_step.${PaymentType.PRODAMUS}`)}
                        </Radio>
                        <Radio value={PaymentType.MTS} className="mb-3">
                            {t(`consultation_sign_up.payment_step.${PaymentType.MTS}`)}
                        </Radio>
                    </Space>
                </Radio.Group>
            </div>

            <Button onClick={pay} type="primary" size="large">
                {t('consultation.PaymentStep.payBtn')} {paymentType !== PaymentType.PRODAMUS ? currencyFormatter(data?.doctor?.price) : currencyFormatter(Number(data?.doctor?.price) * 1.1)}
            </Button>

            <PaymentDirectlyModal
                visible={showPaymentDirectlyModal}
                onClose={() => {
                    setShowPaymentDirectlyModal(false);
                }}
            />
        </div>
    ) : (<></>);
};
