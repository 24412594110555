import {FileSafeDTOBucketType, uploadFile} from '@api/documentServiceAPI';
import {
    AppointmentDTOAppointmentType,
    AppointmentScheduleDTO,
    CarePlanDTO,
    ChangeOkoEventDTO,
    EncounterDetailDTOEncounterStatus,
    EncounterDetailDTOPaymentStatus,
    EncounterDetailDTOStatus,
    EncounterDTODetails,
    EncounterUpdateDTOReasonsItem,
    endEncounterEvent,
    getPatientCarePlans,
    obtainDetailsByEvent,
    obtainEncounterDetails,
    sendPatientGadget,
    takeEncounterEvent,
    TreatmentKitDTO,
    unlinkDocument,
    updateEncounter,
    updatePatientInSlot,
    updateReasonEncounter,
    updateStatusEncounter,
    UserDocumentDTO,
    UserDocumentDTOType,
} from '@api/mainServiceAPI';
import {
    getPaymentInfo, PaymentInfoResponseDTO,
    registerAppointmentPayment,
    registerAppointmentPaymentWithNotification
} from '@api/paymentServiceAPI';
import {AppointmentStatus, NotificationMethodEnum} from '@enums';
import {
    AlertIcon48,
    AlertIconOutlined,
    CalendarIcon,
    CalendarNo,
    ChatWith3DotsIcon,
    CheckCircle48,
    ChevronLeft,
    ChevronRight,
    CrossIcon,
    DiagnosisIcon,
    DocumentIcon,
    EditIcon,
    EyeIcon,
    FileAddedIcon,
    MoreIcon,
    ProcedureFailedIcon, ProcedureTimerIcon, ProcedureTimerIcon1,
    UserIcon,
} from '@icons';
import {FilesUploadModal} from '@modals';
import {setFileViewer} from '@sliceCore';
import {selectAuthUser, UserRole} from '@sliceUser';
import {OrderTypes} from '@utils/constants';
import {Logger} from '@utils/logger';
import {capitalizeText, convertToUserLocalTime, dateFromServer, separateFileNameAndExtension} from '@utils/utils';
import {Badge, Button, Checkbox, Col, Dropdown, Form, Input, MenuProps, message, Row} from 'antd';
import TextArea from 'antd/es/input/TextArea';
import {RcFile} from 'antd/es/upload';
import classNames from 'classnames';
import {format} from 'date-fns';
import dayjs from 'dayjs';
import React, {MouseEvent, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {NavLink, useNavigate, useParams} from 'react-router-dom';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import {Chats} from 'src/components/Chats/Chats';
import {EncounterStatusBadge} from 'src/components/EncounterStatusBadge/EncounterStatusBadge';
import {AidOkoEventStatus} from 'src/enums/aid-oko-event-status.enum';
import {AidOkoEventType} from 'src/enums/aid-oko-event-type.enum';
import {EncounterChangeReasonEnum} from 'src/enums/encounter-change-reason.enum';
import {useFormValidation} from 'src/hooks/form-validation.hook';
import {
    AppointmentChangeConsultationModal
} from 'src/modals/AppointmentChangeConsultationModal/AppointmentChangeConsultationModal';
import {CheckNotExistModal} from 'src/modals/CheckNotExistModal/CheckNotExistModal';
import {UploadFileExt} from 'src/modals/FilesUploadModal/FilesUploadModal';
import {PaymentDirectlyConfirmModal} from 'src/modals/PaymentDirectlyConfirmModal/PaymentDirectlyConfirmModal';
import {getEncounterStatus} from '../../dictionary';
import {CancelProcedureForOneModal} from '../modals/CancelProcedureForOneModal/CancelProcedureForOneModal';
import styles from './CallCenterManagerAppointmentPage.module.scss';
import {selectDeliveryAddressActive, selectPaymentServiceActive} from "../../../../../store/organization";
import {createDeliveryAddressString} from "@utils/addressFormatter";

interface ICallCenterManagerAppointmentPageProps {
    forEvent?: boolean;
}

interface IChangeReasonForm {
    comment: string;
    reasons: EncounterUpdateDTOReasonsItem[];
}

export const CallCenterManagerAppointmentPage = ({forEvent = false}: ICallCenterManagerAppointmentPageProps) => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const params = useParams();
    const dispatch = useDispatch();


    const paymentServiceActive = useSelector(selectPaymentServiceActive);
    const authUser = useSelector(selectAuthUser);
    const deliveryAddressActive = useSelector(selectDeliveryAddressActive);

    const [encounter, setEncounter] = useState<EncounterDTODetails>();
    const [showChangeDateModal, setShowChangeDateModal] = useState(false);
    const [showFileUploadModal, setShowFileUploadModal] = useState(false);
    const [isCourse, setIsCourse] = useState<boolean>(false);
    const [showCancelModal, setShowCancelModal] = useState<boolean>(false);
    const [paymentLink, setPaymentLink] = useState<string>();
    const [showEditReason, setShowEditReason] = useState(false);
    const [showPaymentDirectlyConfirmModal, setShowPaymentDirectlyConfirmModal] = useState(false);
    const [showCheckNotExistModal, setShowCheckNotExistModal] = useState(false);
    const [carePlans, setCarePlans] = useState<CarePlanDTO[]>();
    const [paymentInfo, setPaymentInfo] = useState<PaymentInfoResponseDTO>();
    const [changeReasonForm] = Form.useForm();
    const {validateForm} = useFormValidation(changeReasonForm);


    useEffect(() => {
        retrieveEncounterDetails();
    }, []);

    const getTreatmentKits = (patientFhirId: string | null) => {
        if (patientFhirId) {
            getPatientCarePlans(patientFhirId).then(e => {
                const cpd = e.data.filter(f => f.treatmentKits);
                setCarePlans(cpd);
            });
        }
    }

    const retrievePaymentInfo = (encounterId: string) => {
        getPaymentInfo({
            encounterId,
        }).then((res) => {
            setPaymentInfo(res.data);
        });
    }

    const retrieveEncounterDetails = () => {
        if (params.encounterOrEventId) {
            if (forEvent) {
                obtainDetailsByEvent(params.encounterOrEventId).then((result) => {
                    if (result.data) {
                        setEncounter(result.data);
                        setIsCourse(result.data.encounterDetailDTO?.appointmentType === 'course');
                        getTreatmentKits(result.data.encounterDetailDTO?.patientFhirId ?? null);
                        result.data.encounterDetailDTO?.paymentStatus === 'completed' && retrievePaymentInfo(result.data.encounterDetailDTO.encounterFhirId!);
                    }
                });
            } else {
                obtainEncounterDetails(params.encounterOrEventId).then((result) => {
                    if (result.data) {
                        setEncounter(result.data);
                        setIsCourse(result.data.encounterDetailDTO?.appointmentType === 'course');
                        getTreatmentKits(result.data.encounterDetailDTO?.patientFhirId ?? null);
                        result.data.encounterDetailDTO?.paymentStatus === 'completed' && retrievePaymentInfo(result.data.encounterDetailDTO.encounterFhirId!);
                    }
                });
            }
        }
    };

    const onBackToAllAppointments = () => {
        navigate(forEvent ? '/encounters/events' : '/encounters/all');
    };

    const editPatientSchedule = () => {
        navigate(`/create-schedule?patientId=${encounter?.encounterDetailDTO?.patientFhirId}`);
    }

    const takeInWork = () => {
        const body: ChangeOkoEventDTO = {
            okoEventFhirId: encounter?.okoEventDTO?.id,
            managerId: authUser?.id,
            cancel: false,
        };

        if (!forEvent) {
            body.sourceFhirId = encounter?.encounterDetailDTO?.encounterFhirId;
            body.sourceType = 'Encounter';
        }

        takeEncounterEvent(body).then((result) => {
            if (result) {
                retrieveEncounterDetails();
            }
        });
    };

    // todo check is correct
    const returnEvent = () => {
        endEncounterEvent({
            okoEventFhirId: encounter?.okoEventDTO?.id,
            managerId: authUser?.id,
            cancel: true,
        }).then((result) => {
            if (result) {
                retrieveEncounterDetails();
            }
        });
    };

    const resolveEvent = () => {
        endEncounterEvent({
            okoEventFhirId: encounter?.okoEventDTO?.id,
            managerId: authUser?.id,
            cancel: false,
        }).then((result) => {
            if (result) {
                retrieveEncounterDetails();
            }
        });
    };

    // todo payment logic after backend
    const items: MenuProps['items'] = [
        {
            label: <a
                onClick={() => paymentLink && navigator.clipboard.writeText(paymentLink)}>{t('appointment.paymentOptions.copyLink')}</a>,
            key: '0',
        },
        {
            label: (
                <a
                    onClick={() =>
                        registerAppointmentPaymentWithNotification(
                            {
                                appointmentId: encounter?.encounterDetailDTO?.appointmentFhirId,
                                failUrl: `${process.env.REACT_APP_PAYMENT_URL}-fail?order=${OrderTypes.plan}&entityId=${encounter?.encounterDetailDTO?.appointmentFhirId}`,
                                returnUrl: `${process.env.REACT_APP_PAYMENT_URL}-ok?order=${OrderTypes.plan}&entityId=${encounter?.encounterDetailDTO?.appointmentFhirId}`,
                            },
                            {
                                deliveryMessageChannel: NotificationMethodEnum.EMAIL,
                            },
                        )
                    }
                >
                    {t('appointment.paymentOptions.sendEmail')}
                </a>
            ),
            key: '1',
        },
        // {
        //     label: (
        //         <a
        //             onClick={() =>
        //                 registerAppointmentPaymentWithNotification(
        //                     {
        //                         appointmentId: encounter?.encounterDetailDTO?.appointmentFhirId,
        //                         failUrl: `${process.env.REACT_APP_PAYMENT_URL}-fail?order=${OrderTypes.plan}&entityId=${encounter?.encounterDetailDTO?.appointmentFhirId}`,
        //                         returnUrl: `${process.env.REACT_APP_PAYMENT_URL}-ok?order=${OrderTypes.plan}&entityId=${encounter?.encounterDetailDTO?.appointmentFhirId}`,
        //                     },
        //                     {
        //                         deliveryMessageChannel: NotificationMethodEnum.PHONE,
        //                     },
        //                 )
        //             }
        //         >
        //             {t('appointment.paymentOptions.sendPhone')}
        //         </a>
        //     ),
        //     key: '3',
        // },
    ];

    // todo after payment
    const onPaymentDetails = () => {
        navigate('/');
    };

    const onDoctorDetails = (practitionerUserId?: string) => {
        navigate('/staff/' + practitionerUserId);
    };

    const onPatientDetails = (patientId?: string) => {
        if (encounter?.okoEventDTO?.manager?.id === authUser?.id && encounterStatus === AppointmentStatus.pending) {
            navigate(
                '/medcard/' +
                patientId +
                '?appointmentId=' +
                encounter?.encounterDetailDTO?.appointmentFhirId +
                '&encounterFhirId=' +
                encounter?.encounterDetailDTO?.encounterFhirId +
                '&managerId=' +
                encounter?.okoEventDTO?.manager?.id +
                '&appointmentStatus=' +
                encounterStatus,
            );
        } else {
            navigate('/medcard/' + patientId);
        }
    };

    const onThreatmentResultsDetails = (patientId?: string) => {
        console.log('Open results.');
    };

    const onRemoveDocument = (document: UserDocumentDTO, e: MouseEvent) => {
        e.stopPropagation();
        unlinkDocument({
            documentFhirId: document.documentFhirId,
            encounterFhirId: document.encounterFhirId,
        }).then((result) => {
            if (result) {
                retrieveEncounterDetails();
            }
        });
    };

    const onUploadDocuments = () => {
        setShowFileUploadModal(true);
    };

    const onChangeConsultation = (slot: AppointmentScheduleDTO, reasonType: EncounterChangeReasonEnum, reasonDescription: string) => {
        updatePatientInSlot({
            patientId: encounter?.encounterDetailDTO?.patientFhirId,
            slots: [
                {
                    slotId: encounter?.encounterDetailDTO?.slotFhirId,
                    newSlotId: slot.slotId,
                    serviceType: encounter?.encounterDetailDTO?.serviceType,
                    appointmentType: encounter?.encounterDetailDTO?.appointmentType,
                },
            ],
        }).then(() => {
            updateReasonEncounter({
                encounterFhirId: encounter?.encounterDetailDTO?.encounterFhirId,
                managerFhirId: authUser?.id,
                reasonType: reasonType,
                description: reasonDescription,
            }).then(() => {
                if (params.encounterOrEventId) {
                    retrieveEncounterDetails();
                    setShowCancelModal(false);
                    message.success(t("call_center_manager_appointment_page.appointment_moved"));
                }
            });
        });
    };

    const onCancelConsultation = (reason: EncounterChangeReasonEnum) => {
        if (encounter?.encounterDetailDTO?.encounterFhirId) {
            updateStatusEncounter(encounter?.encounterDetailDTO?.encounterFhirId, {aidEncounterStatus: 'cancelled'}).then((result) => {
                if (result) {
                    updateReasonEncounter({
                        encounterFhirId: encounter?.encounterDetailDTO?.encounterFhirId,
                        managerFhirId: authUser!.id,
                        reasonType: reason,
                        description: '',
                    }).then((res) => {
                        retrieveEncounterDetails();
                        setShowCancelModal(false);
                        message.success(t("call_center_manager_appointment_page.appointment_cancelled"));
                    });
                }
            });
        }
    };

    const getManagerName = () => {
        return (
            encounter?.okoEventDTO?.manager?.display &&
            encounter.okoEventDTO.manager?.display?.split(' ')[0] + ' ' + encounter.okoEventDTO.manager?.display?.split(' ')[1].substring(0, 1) + '.'
        );
    };

    const canEdit = (): boolean => {
        return encounter?.okoEventDTO?.status === AidOkoEventStatus.in_progress && encounter?.okoEventDTO?.manager?.id === authUser?.id;
    };

    const uploadDocuments = async (files: UploadFileExt[]) => {
        const uploadRequests: Promise<void>[] = [];
        const resultFilesIds: string[] = [];

        for (let index = 0; index < files.length; index++) {
            const f = files[index];

            if (f.done || f.loading) {
                return;
            }
            f.loading = true;

            const request = uploadFile(
                {
                    fhirId: encounter?.encounterDetailDTO?.patientFhirId,
                    type: f.docType,
                    file: f as RcFile,
                    typeDescription: f.otherType,
                    ...separateFileNameAndExtension(f.name),
                },
                {headers: {'Content-Type': 'multipart/form-data'}},
            )
                .then((res: any) => {
                    f.done = true;
                    f.loading = false;
                    f.uuid = res.data.uuid;

                    resultFilesIds.push(res.data.uuid);
                })
                .catch((err) => {
                    Logger.error(err);
                });

            uploadRequests.push(request);
        }

        await Promise.all(uploadRequests);

        if (encounter?.encounterDetailDTO?.encounterFhirId) {
            updateEncounter(encounter.encounterDetailDTO.encounterFhirId, {
                attachDocumentsUuid: resultFilesIds,
            }).then(() => {
                retrieveEncounterDetails();
                message.success(t("call_center_manager_appointment_page.documents_uploaded"));
            });
        }
    };

    const openDocument = (doc: UserDocumentDTO & { s3Key?: string }) => {
        const key = doc.s3key || doc.s3Key;

        if (key) {
            dispatch(
                setFileViewer({
                    s3Key: key,
                    bucketType: doc.type === FileSafeDTOBucketType.VIDEO ? FileSafeDTOBucketType.VIDEO : FileSafeDTOBucketType.DOCUMENT,
                    fileName: doc.extension ? `${doc.name}.${doc.extension}` : (doc.name as string),
                    extension: doc.extension,
                }),
            );
        }
    };

    const encounterStatus = getEncounterStatus(encounter?.encounterDetailDTO?.status, encounter?.encounterDetailDTO?.paymentStatus);

    const onProcedureDetails = (id: string) => {
        if (forEvent) {
            navigate(`/encounters/events/procedure/${id}`);
        } else {
            navigate(`/encounters/all/procedure/${id}`);
        }
    };

    const openCashReceipt = () => {
        window.open(paymentInfo?.receiptUrl![0]);
    }

    const generatePaymentLink = () => {
        registerAppointmentPayment({
            appointmentId: encounter?.encounterDetailDTO?.appointmentFhirId,
            failUrl: `${process.env.REACT_APP_PAYMENT_URL}-fail?order=${OrderTypes.consultation}&entityId=${encounter?.encounterDetailDTO?.appointmentFhirId}`,
            returnUrl: `${process.env.REACT_APP_PAYMENT_URL}-ok?order=${OrderTypes.consultation}&entityId=${encounter?.encounterDetailDTO?.appointmentFhirId}`,
        }).then((result) => {
            if (result.data) {
                setPaymentLink(result.data.formUrl);
            }
        });
    };

    const validate = () => {
        const fieldsToCheck = ['lastName', 'firstName', 'mobile', 'email', 'role'];
        validateForm(fieldsToCheck);
    };

    const changeReason = (formData: IChangeReasonForm) => {
        if (!changeReasonForm) {
            return;
        }

        changeReasonForm.validateFields();
        setTimeout(() => {
            if (!changeReasonForm.getFieldsError().find(({errors}: { errors: string[] }) => errors.length > 0)) {
                updateEncounter(encounter!.encounterDetailDTO!.encounterFhirId!, {
                    reasons: formData.reasons,
                    comment: formData.comment,
                }).then(() => {
                    retrieveEncounterDetails();
                    message.success(t("call_center_manager_appointment_page.reason_changed"));
                });
            }
        });
    };

    const isDocumentFromPatients = (document: UserDocumentDTO): boolean => {
        return (
            document.type === UserDocumentDTOType.DISCHARGE_SUMMARY ||
            document.type === UserDocumentDTOType.CONSULTATION ||
            document.type === UserDocumentDTOType.PERSONAL_DATA_STATEMENT ||
            document.type === UserDocumentDTOType.CERTIFIED_OPHTHALMOLOGISTS_REPORT ||
            document.type === UserDocumentDTOType.ANALYZES ||
            document.type === UserDocumentDTOType.SNAPSHOTS ||
            document.type === UserDocumentDTOType.OTHER
        );
    };

    const canApproveDirectly = (): boolean => {
        return (
            encounter?.okoEventDTO?.manager?.id === authUser?.id &&
            encounter?.encounterDetailDTO?.paymentStatus === EncounterDetailDTOPaymentStatus.pending
        );
    };

    const sendTreatmentKit = (gadget: TreatmentKitDTO) => {
        const patientId = encounter?.encounterDetailDTO?.patientFhirId;
        sendPatientGadget(patientId!, gadget.id!).then(() => {
            getTreatmentKits(patientId!);
            message.success(t("userMainPage.courseRecommended.delivery_address_send_success_message"));
        });
    }

    return encounter ? (
        <div>
            <div className="d-flex justify-content-between">
                <div className={styles.titleContainer}>
                    <Button onClick={() => onBackToAllAppointments()} className="backBtn">
                        <ChevronLeft className="transparent"/>
                    </Button>
                    <p className={styles.title}>
                        {encounter?.encounterDetailDTO?.appointmentType && encounter?.encounterDetailDTO?.appointmentType === 'diagnostic'
                            ? t(`patientAppointmentItemPage.title.diagnosticPlanDefinition.${encounter?.partOf}`)
                            : encounter?.encounterDetailDTO?.appointmentType && encounter?.encounterDetailDTO?.appointmentType === 'course'
                                ? t(`patientAppointmentItemPage.title.coursePlanDefinition.${encounter?.partOf}`)
                                : t(`patientAppointmentItemPage.title.${encounter?.encounterDetailDTO?.appointmentType}`)}
                    </p>
                </div>
            </div>

            <Row gutter={24}>
                <Col md={16} sm={24} xs={24}>
                    <Col md={24} sm={24} xs={24}>
                        <div className={styles.eventTopBlock}>
                            <div className={styles.eventField}>
                                {forEvent ? (
                                    <>
                                        {encounter?.okoEventDTO?.type && forEvent && (
                                            <div
                                                className={classNames(
                                                    encounter?.okoEventDTO?.isActive
                                                        ? encounter?.okoEventDTO?.manager?.id
                                                            ? styles.statusSuccess
                                                            : styles.statusError
                                                        : styles.statusInfo,
                                                    styles.typeIcon,
                                                )}
                                            >
                                                {encounter?.okoEventDTO?.type === AidOkoEventType.new_message ? (
                                                    <ChatWith3DotsIcon/>
                                                ) : encounter?.okoEventDTO?.type === AidOkoEventType.new_document ? (
                                                    <DocumentIcon/>
                                                ) : encounter?.okoEventDTO?.type === AidOkoEventType.new_patient ? (
                                                    <DocumentIcon/>
                                                ) : (
                                                    <CalendarNo/>
                                                )}
                                            </div>
                                        )}

                                        <div>
                                            <p>
                                                {encounter?.okoEventDTO?.type &&
                                                    t(`callCenterManager.encounterEventTypes.${encounter?.okoEventDTO?.type}`)}
                                            </p>
                                            <p className={styles.secondaryField}>
                                                {encounter?.okoEventDTO?.dateTime &&
                                                    format(new Date(encounter?.okoEventDTO?.dateTime), 'dd.MM.yyyy HH:mm')}
                                            </p>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div>
                                            <p>{t("appointment_card")}</p>
                                            <p className={styles.secondaryField}>
                                                {encounter?.encounterDetailDTO?.dateTime &&
                                                    format(new Date(encounter?.encounterDetailDTO?.dateTime), 'dd.MM.yyyy HH:mm')}
                                            </p>
                                        </div>
                                    </>
                                )}
                            </div>
                            <div className="d-flex">
                                {encounter?.okoEventDTO?.status === AidOkoEventStatus.in_progress && encounter?.okoEventDTO?.manager?.display && (
                                    <div style={{marginRight: '24px'}}>
                                        <p className={styles.managerLabel}>{t('appointment.manager')}</p>
                                        <p className={styles.managerName}>{getManagerName()}</p>
                                    </div>
                                )}
                                {encounter?.okoEventDTO?.status === AidOkoEventStatus.in_progress &&
                                    encounter?.okoEventDTO?.manager?.id === authUser?.id && (
                                        <>
                                            <Button onClick={() => returnEvent()}>{t('appointment.return')}</Button>
                                            <Button type="primary" style={{marginLeft: '8px'}}
                                                    onClick={() => resolveEvent()}>
                                                {t('appointment.issueResolved')}
                                            </Button>
                                        </>
                                    )}
                                {encounter?.okoEventDTO?.status !== AidOkoEventStatus.in_progress && (
                                    <Button type="primary" style={{marginLeft: '8px'}} onClick={() => takeInWork()}>
                                        {t('appointment.takeInWork')}
                                    </Button>
                                )}
                            </div>
                        </div>
                    </Col>
                    <Col md={24} sm={24} xs={24}>
                        <div className={styles.eventBottomBlock}>
                            {isCourse && (
                                <div className={styles.infoField}>
                                    <div className="d-flex align-items-center">
                                        <div>
                                            <EyeIcon/>
                                        </div>
                                        <div>
                                            <p className={styles.nameProcedure}>
                                                {encounter?.partOf && t(`enums.planDefinition.${encounter?.partOf}`)}
                                            </p>
                                        </div>
                                    </div>
                                    <Button
                                        onClick={() => onProcedureDetails(encounter!.encounterDetailDTO!.appointmentFhirId!)}
                                        className="gotoBtn">
                                        <ChevronRight/>
                                    </Button>
                                </div>
                            )}
                            <div className={styles.infoField}>
                                <div>
                                    <div className="d-flex">
                                        <div>
                                            <CalendarIcon/>
                                        </div>
                                        <div>
                                            <p>{t(`appointment.dateTime`)}</p>
                                            <div className="d-flex">
                                                <p className={styles.dateText}>
                                                    {encounter?.encounterDetailDTO?.dateTime &&
                                                        format(new Date(encounter?.encounterDetailDTO?.dateTime), 'dd.MM.yyyy HH:mm')}
                                                </p>
                                                <div className={styles.statusField}>
                                                    <EncounterStatusBadge
                                                        encounterStatus={encounter?.encounterDetailDTO?.status}
                                                        paymentStatus={encounter?.encounterDetailDTO?.paymentStatus}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={styles.timeHistoryContainer}>
                                        {
                                            encounter.timeHistories?.map(th =>
                                                <>
                                                        <div className={styles.timeHistory}>
                                                            <span className={styles.th_dateTime}>{dayjs(convertToUserLocalTime(new Date(Number(th.date![0]), Number(th.date![1]) - 1, Number(th.date![2]), Number(th.date![3]), Number(th.date![4]), Number(th.date![5])))).format("HH:mm")}</span>
                                                            <span className={styles.th_notice}>{t("appointment.time_history." + th.status)}</span>
                                                        </div>
                                                </>
                                            )
                                        }
                                    </div>
                                    {encounter?.encounterDetailDTO?.status === EncounterDetailDTOStatus.cancelled && (
                                        <div className={styles.cancelReasonBlock}>
                                            <AlertIconOutlined/>
                                            <p>{t('appointment.cancelReason')}</p>
                                            <p>
                                                {t(
                                                    'enums.encounterChangeReason.' + encounter?.encounterDetailDTO?.changeReason?.at(-1)?.type,
                                                    t("not_specified_1"),
                                                )}
                                            </p>
                                        </div>
                                    )}
                                </div>

                                {canEdit() && (
                                    <Button
                                        onClick={() => {
                                            if (isCourse) {
                                                editPatientSchedule();
                                            } else {
                                                setShowChangeDateModal(true);
                                            }
                                        }}
                                    >
                                        {isCourse ? t('appointment.changeSchedule') : t('appointment.change')}
                                    </Button>
                                )}
                            </div>

                            {paymentServiceActive && !isCourse && paymentInfo && encounter?.encounterDetailDTO?.paymentStatus === EncounterDetailDTOPaymentStatus.completed && (
                                <div className={styles.infoField}>
                                    <div className="d-flex">
                                        <div className={styles.circleGreen}>
                                            <CheckCircle48/>
                                        </div>
                                        <div>
                                            <p>
                                                {capitalizeText(
                                                    t(`callCenterManager.paymentStatuses.${encounter?.encounterDetailDTO?.paymentStatus}`),
                                                )}
                                            </p>
                                            <div className="d-flex">
                                                <div className={styles.statusField}>
                                                    <p className={styles.dateTextSmall}>
                                                        <p>Дата/время оплаты: <span
                                                            className={styles.blackColor}>{paymentInfo?.approvedIn &&
                                                            dayjs(paymentInfo?.approvedIn).format('DD.MM.YYYY HH:mm:ss')}</span>
                                                        </p>
                                                    </p>
                                                    <p className={styles.dateTextSmall}>
                                                        <p>Способ оплаты: <span className={styles.blackColor}>{
                                                            t("appointment.paymentMethod." + (paymentInfo.paymentMethod ? paymentInfo.paymentMethod : 'NO_DATA'))}</span>
                                                        </p>
                                                    </p>
                                                    <p className={styles.dateTextSmall}>
                                                        <p>Сумма оплаты: <span
                                                            className={styles.blackColor}>{paymentInfo?.amount &&
                                                            paymentInfo?.amount}₽</span></p>
                                                    </p>
                                                    {paymentInfo?.receiptUrl ?
                                                        <NavLink className={styles.checkButton} to=""
                                                                 onClick={() => openCashReceipt()}>
                                                            {t('appointment.seeCheck')}
                                                        </NavLink> :
                                                        paymentInfo?.paymentMethod !== 'SBERBANK' ?
                                                            <span className={styles.noCheck}>
                                                                <ProcedureFailedIcon
                                                                    className={styles.failIcon}></ProcedureFailedIcon>
                                                                {t("appointment.noCheck")}
                                                        </span> :
                                                            <>
                                                            <span className={styles.waitCheckDisplayInfo}>
                                                                <ProcedureTimerIcon1
                                                                    className={styles.progressIcon}></ProcedureTimerIcon1>
                                                                {t("appointment.waitCheck")}
                                                            </span>
                                                            {paymentInfo?.paymentMethod === 'SBERBANK' && paymentInfo?.approvedIn && ((new Date().getTime() - paymentInfo?.approvedIn) > 86400000) && <div className={styles['notice']}>
                                                                    <AlertIconOutlined className={styles.failIcon} />
                                                                    {t("appointment.checkNotation") + ' ' + t("appointment.paymentMethod." + paymentInfo.paymentMethod)}
                                                                </div>}
                                                            </>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* {canEdit() && (
                                        <Dropdown menu={{ items }} trigger={['click']}>
                                            <Button className={classNames(styles.nextButton, 'p-0')}>
                                                <MoreIcon />
                                            </Button>
                                        </Dropdown>
                                    )} */}
                                </div>
                            )}
                            {!isCourse && encounter?.encounterDetailDTO?.paymentStatus === EncounterDetailDTOPaymentStatus.pending && (
                                <div className={styles.infoField}>
                                    <div className="d-flex">
                                        <div className={styles.circleAlert}>
                                            <AlertIcon48/>
                                        </div>
                                        {paymentLink ? (
                                            <div>
                                                <p>
                                                    {capitalizeText(
                                                        t(`callCenterManager.paymentStatuses.${encounter?.encounterDetailDTO?.paymentStatus}`),
                                                    )}
                                                </p>
                                                <div className="d-flex">
                                                    <div>
                                                        <NavLink className={styles.checkButton} to={paymentLink}>
                                                            {t('appointment.paymentLink')}
                                                        </NavLink>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (
                                            <div className="d-flex align-items-center">
                                                <p>
                                                    {capitalizeText(
                                                        t(`callCenterManager.paymentStatuses.${encounter?.encounterDetailDTO?.paymentStatus}`),
                                                    )}
                                                </p>
                                            </div>
                                        )}
                                    </div>

                                    <div>
                                        {canEdit() &&
                                            (!paymentLink ? (
                                                <Button
                                                    onClick={() => generatePaymentLink()}>{t('appointment.generatePaymentLink')}</Button>
                                            ) : (
                                                <Dropdown menu={{items}} trigger={['click']}>
                                                    <Button className={classNames(styles.nextButton, 'p-0')}>
                                                        <MoreIcon/>
                                                    </Button>
                                                </Dropdown>
                                            ))}

                                        {canApproveDirectly() && (
                                            <Button style={{marginLeft: '16px'}}
                                                    onClick={() => setShowPaymentDirectlyConfirmModal(true)}>
                                                {t('appointment.approvePayment')}
                                            </Button>
                                        )}
                                    </div>
                                </div>
                            )}

                            {encounter?.encounterDetailDTO?.practitionerId && (
                                <div className={styles.infoField}>
                                    <div className="d-flex align-items-center">
                                        <div className={styles.avatar}>
                                            {encounter?.encounterDetailDTO?.practitionerPhoto ? (
                                                <img src={encounter?.encounterDetailDTO?.practitionerPhoto}/>
                                            ) : (
                                                <UserIcon/>
                                            )}
                                        </div>
                                        <div>
                                            <p
                                                onClick={() => onDoctorDetails(encounter?.encounterDetailDTO?.practitionerId)}
                                                className={styles.nameText}
                                            >
                                                {encounter?.encounterDetailDTO?.practitionerName}
                                            </p>
                                            <div className="d-flex">
                                                <p>
                                                    {encounter?.encounterDetailDTO?.practitionerRoleCode === 'nurse'
                                                        ? t(`appointment.practitionerRoles.nurse`)
                                                        : t(`enums.speciality.${encounter?.encounterDetailDTO?.practitionerSpecialty}`)}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}

                            {encounter?.encounterDetailDTO?.patientFhirId && (
                                <div className={styles.infoField}>
                                    <div className="d-flex">
                                        <div className={styles.avatar}>
                                            <UserIcon/>
                                        </div>
                                        <div>
                                            <p
                                                onClick={() => onPatientDetails(encounter?.encounterDetailDTO?.patientFhirId)}
                                                className={styles.nameText}
                                            >
                                                {encounter?.encounterDetailDTO?.patientName}
                                            </p>
                                            <div className="d-flex">
                                                <p>{dayjs(encounter?.encounterDetailDTO?.patientBirthDay).fromNow(true)}</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="d-flex align-items-center">
                                        {!encounter?.encounterDetailDTO?.checked && (
                                            <div className={styles.newPatient}>
                                                <p>{t('appointment.newPatient')}</p>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )}
                        </div>
                    </Col>

                    {/* Change reasons/complaints - First Consultation Block */}
                    {(encounterStatus === AppointmentStatus.created ||
                            encounterStatus === AppointmentStatus.confirmed ||
                            encounterStatus === AppointmentStatus.pending) &&
                        encounter?.encounterDetailDTO?.appointmentType == AppointmentDTOAppointmentType.initial_consultation && (
                            <Col md={24} sm={24} xs={24}>
                                <h3 className={styles.reasonText}>{t('appointment.reason')}</h3>
                                {showEditReason ? (
                                    <div className={styles.changeReasonBlock}>
                                        <Form
                                            form={changeReasonForm}
                                            onFinish={changeReason}
                                            name="changeReasonForm"
                                            layout="vertical"
                                            wrapperCol={{span: 24}}
                                            initialValues={{
                                                comment: encounter.encounterDetailDTO.comment,
                                                reasons: encounter.encounterDetailDTO.reasonCodes || [],
                                            }}
                                            autoComplete="off"
                                            requiredMark={false}
                                            onChange={validate}
                                        >
                                            <Row gutter={24}>
                                                <Col md={24} sm={24} xs={24}>
                                                    <p className={styles.fieldLabel}>{t('appointment.diagnosis')}</p>
                                                </Col>
                                                <Col md={24} sm={24} xs={24} className="mt-1">
                                                    <Input
                                                        disabled
                                                        value={
                                                            encounter?.encounterDetailDTO?.diagnosis &&
                                                            encounter?.encounterDetailDTO?.diagnosis
                                                                .map((item) => t(`consultationSession.patientInfoStep.preliminaryDiagnosis.${item}`))
                                                                ?.join(', ')
                                                        }
                                                    />
                                                </Col>
                                                <Col md={24} sm={24} xs={24} className="mt-4">
                                                    <p className={styles.fieldLabel}>{t('appointment.complaints.title')}</p>
                                                </Col>

                                                <Col md={24} sm={24} xs={24} className="mt-4">
                                                    <Form.Item name="reasons">
                                                        <Checkbox.Group className="d-flex flex-column">
                                                            {Object.keys(EncounterUpdateDTOReasonsItem).map((x) => (
                                                                <Checkbox style={{marginInlineStart: 0}} key={x}
                                                                          value={x}>
                                                                    {t(`consultationSession.patientInfoStep.reasonCodes.${x}`)}
                                                                </Checkbox>
                                                            ))}
                                                        </Checkbox.Group>
                                                    </Form.Item>
                                                </Col>
                                            </Row>

                                            <Row gutter={24}>
                                                <Col md={24} sm={24} xs={24}>
                                                    <Form.Item name="comment" className={styles.descriptionField}>
                                                        <TextArea/>
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                            <div key="block" className="d-flex justify-content-end">
                                                <Button onClick={() => setShowEditReason(false)} key="back">
                                                    {t('appointment.cancel')}
                                                </Button>
                                                <Button
                                                    onClick={() => {
                                                        changeReasonForm.submit();
                                                        setShowEditReason(false);
                                                    }}
                                                    key="cancel"
                                                    type="primary"
                                                    style={{marginLeft: '16px'}}
                                                >
                                                    {t('appointment.saveChanges')}
                                                </Button>
                                            </div>
                                        </Form>
                                    </div>
                                ) : (
                                    <div className={styles.reasonBlock}>
                                        <div className={styles.infoField}>
                                            <div className="d-flex position-relative me-0 w-100">
                                                <div>
                                                    <DiagnosisIcon/>
                                                </div>
                                                <div>
                                                    {canEdit() && <EditIcon className={styles.editIcon}
                                                                            onClick={() => setShowEditReason(true)}/>}

                                                    <p>
                                                        {t("diagnosis")}:{' '}
                                                        {encounter.encounterDetailDTO.diagnosis
                                                            ?.map((x) => t('enums.preliminaryDiagnosis.' + x))
                                                            .join(', ') || t("call_center_manager_appointment_page.dont_know")}
                                                    </p>

                                                    {!!encounter?.encounterDetailDTO.reasonCodes?.length && (
                                                        <ul className="mb-0">
                                                            {encounter?.encounterDetailDTO.reasonCodes.map((x, i) => (
                                                                <li key={i}>{t('consultationSession.patientInfoStep.reasonCodes.' + x)}</li>
                                                            ))}
                                                        </ul>
                                                    )}
                                                    <p>{encounter.encounterDetailDTO?.comment}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </Col>
                        )}
                    {<Col md={24} sm={24} xs={24}>
                        <>
                            <h3 className={styles.reasonText}>{t('userMainPage.courseRecommended.oko_complex')}</h3>
                            {carePlans?.map((carePlan) => (
                                carePlan.treatmentKits?.map(treatmentKit => {
                                    return <>
                                        <div className="d-flex align-items-start justify-content-start gap-3 mt-4">
                                            <EyeIcon/>
                                            <div>
                                                <p className={styles.courseName}>{t(`consultationSession.courseStep.devices.${treatmentKit.id}`)}</p>
                                                <div className="d-flex align-items-center justify-content-start gap-3">
                                                    <p className={styles.extendedStatus}>{carePlan.extended ? t("course_step.extended_course") : t("course_step.non_extended_course")}</p>
                                                    <p>{carePlan.status === 'on-hold' || carePlan.status === 'draft' ?
                                                        <Badge dot={false} color="red"
                                                               text={t("callCenterManager.paymentStatuses.pending")}></Badge> :
                                                        <Badge dot={true} color="green"
                                                               text={t("callCenterManager.paymentStatuses.completed")}></Badge>}</p>
                                                    <p>{
                                                        treatmentKit.deliveryStatus === 'CREATED' &&
                                                        <Badge dot={false} color="red"
                                                               text={t("userMainPage.courseRecommended.delivery_not_sent_yet")}></Badge> ||
                                                        treatmentKit.deliveryStatus === 'SENT' &&
                                                        <Badge dot={true} color="orange"
                                                               text={t("userMainPage.courseRecommended.delivery_sent")}></Badge> ||
                                                        treatmentKit.status === 'APPROVED' &&
                                                        <Badge dot={true} color="green"
                                                               text={treatmentKit.gadgetDeliveryAddress ? t("userMainPage.courseRecommended.delivery_approved") : t("userMainPage.courseRecommended.treatment_kit_approved")}></Badge>
                                                    }
                                                    </p>
                                                </div>
                                                <div>
                                                    {treatmentKit.gadgetDeliveryAddress &&
                                                        <p className={styles.deliveryAddress}>{t("userMainPage.courseRecommended.delivery_address_treatment_kit")}</p>}
                                                    {treatmentKit.gadgetDeliveryAddress &&
                                                        <p className={styles.addressDefinition}>{createDeliveryAddressString(t, treatmentKit.gadgetDeliveryAddress)}</p>}
                                                    {deliveryAddressActive && carePlan.extended && !treatmentKit.gadgetDeliveryAddress && treatmentKit.status === 'PROPOSED' &&
                                                        <p className={styles.addressNotSpecified}>
                                                            <ProcedureFailedIcon/>{t("userMainPage.courseRecommended.delivery_address_not_defined_ext")}
                                                        </p>}
                                                </div>
                                                {deliveryAddressActive && treatmentKit.gadgetDeliveryAddress &&
                                                    treatmentKit.status === 'PROPOSED' &&
                                                    treatmentKit.deliveryStatus === 'CREATED' &&
                                                    carePlan.status === 'active' &&
                                                    <Button
                                                        className="mt-2"
                                                        type="primary"
                                                        onClick={() => sendTreatmentKit(treatmentKit)}
                                                    >
                                                        {t('userMainPage.courseRecommended.delivery_address_send_button')}
                                                    </Button>}
                                            </div>
                                        </div>
                                    </>
                                })
                            ))}
                        </>
                    </Col>}
                    <Col md={24} sm={24} xs={24}>
                        {!!encounter?.documents?.filter((x) => isDocumentFromPatients(x))?.length && (
                            <>
                                <h3 className={styles.reasonText}>{t('appointment.loadedDocuments')}</h3>
                                <div className={styles.documentsBlock}>
                                    {encounter.documents
                                        .filter((x) => isDocumentFromPatients(x))
                                        .map((item) => (
                                            <div className={styles.documentBlock} key={item.documentFhirId}
                                                 onClick={() => openDocument(item)}>
                                                <div className="d-flex">
                                                    <div>
                                                        <FileAddedIcon/>
                                                    </div>
                                                    <div>
                                                        <h5 className={styles.documentLabel}>{t(`appointment.documentTypes.${item.type}`)}</h5>
                                                        <p>{item.name}</p>
                                                    </div>
                                                </div>

                                                {canEdit() && (
                                                    <Button type="ghost" onClick={(e) => onRemoveDocument(item, e)}
                                                            className={styles.nextButton}>
                                                        <CrossIcon/>
                                                    </Button>
                                                )}
                                            </div>
                                        ))}
                                </div>
                            </>
                        )}

                        {!isCourse && canEdit() && encounter?.encounterDetailDTO?.encounterStatus !== EncounterDetailDTOEncounterStatus.cancelled && (
                            <>
                                {!encounter?.documents?.length &&
                                    <h3 className={styles.reasonText}>{t('appointment.loadedDocuments')}</h3>}
                                <Button onClick={() => onUploadDocuments()}>{t('appointment.loadDocuments')}</Button>
                            </>
                        )}
                    </Col>
                </Col>
                <Col md={8} sm={24} xs={24}>
                    <Chats
                        currentUserRole={UserRole.Call_center_manager}
                        currentUserId={authUser?.id}
                        isProcedure={encounter?.encounterDetailDTO?.serviceType === 'therapy-session'}
                        chats={encounter?.chats}
                        haveAccessForEdit={encounter?.okoEventDTO?.manager?.id === authUser?.id && encounter?.okoEventDTO?.status === 'in_progress'}
                    />
                </Col>
            </Row>

            {showCancelModal && (
                <CancelProcedureForOneModal
                    patientFullName={encounter!.encounterDetailDTO!.patientName || ''}
                    encounter={encounter!}
                    showModal={showCancelModal}
                    onSuccess={onCancelConsultation}
                    onCancel={() => setShowCancelModal(false)}
                    onChangeProcedure={onChangeConsultation}
                />
            )}

            {showChangeDateModal && (
                <AppointmentChangeConsultationModal
                    onClose={() => setShowChangeDateModal(false)}
                    onCancelConsultation={onCancelConsultation}
                    onChangeConsultation={onChangeConsultation}
                    showModal={showChangeDateModal}
                    encounter={encounter}
                />
            )}

            {showFileUploadModal && (
                <FilesUploadModal
                    onCancel={() => setShowFileUploadModal(false)}
                    onSuccess={(files) => {
                        // setData({ ...data, filesToUpload: files });
                        uploadDocuments(files);
                        setShowFileUploadModal(false);
                    }}
                    files={[]}
                    showModal={showFileUploadModal}
                />
            )}

            {showPaymentDirectlyConfirmModal && (
                <PaymentDirectlyConfirmModal
                    encounterId={encounter?.encounterDetailDTO?.encounterFhirId}
                    visible={showPaymentDirectlyConfirmModal}
                    onClose={() => {
                        setShowPaymentDirectlyConfirmModal(false);
                    }}
                />
            )}

            {showCheckNotExistModal &&
                <CheckNotExistModal visible={showCheckNotExistModal} onClose={() => setShowCheckNotExistModal(false)}/>}
        </div>
    ) : null;
};
